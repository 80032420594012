import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { CART_TYPE } from '@/constants/cart';
import { AVAILABLE_STOCK } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import { useAppSelector } from '@/hooks/reduxHooks';
import useTriggerEvents from '@/hooks/useTriggerEvents';
import { ProductVariant } from '@/models/Product';
import { selectCurrentVendor } from '@/store/features/locationSlice';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { isShriProgramProduct, productCatalogueId, productDiscount, productPrice } from '@/utils/cart';
import '@/utils/helper';
import BottomDrawer from '../BottomDrawer';
import ButtonLabel, { VARIANT } from '../ButtonLabel';
import HyperLocalAddRemoveButton from '../products/HyperLocalAddRemoveButton';
import ProductVariantList from '../products/ProductVariantList';
interface Props {
  activeVariants: ProductVariant[];
  index?: number;
  listType?: ProductListTypeEnum;
  minQty?: number;
  productVariant: ProductVariant;
  maxQty?: number;
  isDrawerVisible?: boolean;
  isOutOfStock?: boolean;
}
const AddToCart = ({
  activeVariants,
  index,
  listType,
  minQty,
  productVariant,
  isOutOfStock
}: Props) => {
  const {
    t
  } = useTranslation('products');
  const [showVariantDrawer, setShowVariantDrawer] = useState(false);
  const bottomDrawerRef = useRef<HTMLDivElement>(null);
  const {
    trackCustomEvent: trackCustomEventUsingHook
  } = useTriggerEvents();
  const vendorDetails = useAppSelector(selectCurrentVendor);
  const router = useRouter();
  const {
    cartItems,
    updateCart,
    cartType
  } = useContext(CartContext);
  const stopBubbling: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const StockInSS = typeof window !== 'undefined' && sessionStorage?.getItem(AVAILABLE_STOCK);
  const availableStock = StockInSS ? JSON.parse(StockInSS) : undefined;
  const quantity = cartItems[productVariant?.id] || 0;
  const disableRemove = !(typeof minQty === 'undefined' || quantity > minQty);
  const diasbleAdd = availableStock && availableStock[productVariant.id] !== 'undefined' && quantity >= availableStock[productVariant.id];
  const disableEditQuantity = isShriProgramProduct(productVariant?.id, false);
  useEffect(() => {
    const preventDefault = (e: globalThis.MouseEvent) => e.preventDefault();
    const {
      current
    } = bottomDrawerRef;
    if (current) {
      current.addEventListener('click', preventDefault);
    }
    return () => {
      if (current) {
        current.removeEventListener('click', preventDefault);
      }
    };
  }, [activeVariants]);
  const onAddOrRemoveFromCart = (isAdd: boolean) => {
    let name, brandName;
    if (cartType === CART_TYPE.DEHAAT) {
      name = (productVariant as ProductVariant).product?.name;
      brandName = (productVariant as ProductVariant).product?.brand?.name;
    }
    updateCart({
      variantId: productVariant.id,
      quantity: 1,
      index,
      type: listType,
      variantPrice: productPrice(productVariant.inventory_set),
      variantDiscount: productDiscount(productVariant.inventory_set),
      name,
      brandName,
      catalogueId: productCatalogueId(productVariant.inventory_set)
    }, isAdd);
  };
  return <>
      {quantity == 0 ? <button className={` rounded-lg py-2.5 justify-center items-center mt-2 ${'w-full flex-1'}
            ${isOutOfStock ? 'bg-[#C5CED6]' : 'bg-primary-100'}`} onClick={e => {
      if (isOutOfStock) {
        return null;
      }
      if (activeVariants?.length == 1) {
        onAddOrRemoveFromCart(true);
        const eventParam = {
          ProductName: productVariant.product?.name,
          ProductVariant: productVariant.product?.id,
          ProductBrand: productVariant.product?.brand?.name,
          ProductPrice: productVariant.inventory_set[0].price,
          Page: router.asPath,
          VendorName: vendorDetails?.company_name,
          VendorId: vendorDetails?.id
        };
        trackCustomEventUsingHook(CustomEventTypes.ADD_TO_CART, JSON.stringify(eventParam));
        stopBubbling(e);
      } else {
        setShowVariantDrawer(true);
      }
    }}>
          <ButtonLabel variant={VARIANT.TWO} className="ml-2 font-nato-semibold text-white" label={t('add')} />
        </button> : <HyperLocalAddRemoveButton disableRemove={disableRemove || disableEditQuantity} disableAdd={diasbleAdd || disableEditQuantity} onAdd={() => {
      trackCustomEvent(CustomEventTypes.ADD_ONE_ITEM_TO_CART);
      onAddOrRemoveFromCart(true);
    }} onRemove={() => {
      trackCustomEvent(CustomEventTypes.REMOVE_ONE_ITEM_FROM_CART);
      onAddOrRemoveFromCart(false);
    }} quantity={quantity} />}

      {activeVariants?.length > 1 ? <BottomDrawer show={showVariantDrawer} onClose={() => setShowVariantDrawer(false)} ref={bottomDrawerRef} drawerStyle={'md:max-w-xl'}>
          <section className="bg-gray-10 shadow-[0_-4px_4px_rgba(0,0,0,0.07)] px-4 pt-4 pb-6 rounded-t-xl">
            <ProductVariantList productVariants={activeVariants} listType={ProductListTypeEnum.PRODUCT} onContine={() => setShowVariantDrawer(false)} />
          </section>
        </BottomDrawer> : null}
    </>;
};
export default AddToCart;